import React from 'react';

import Layout from '../components/templates/layout';
import SEO from '../components/templates/seo';

const DataPrivacyPage = () => (
  <Layout pageInfo={{ pageName: 'privacy' }}>
    <SEO title="MissionMe - Datenschutz" />
    <h1 id="datenschutz-hinweise">Datenschutz-Hinweise</h1>
    <p>
      Du möchtest wissen, wie wir mit dem Thema Datenschutz umgehen? Uns liegt es am Herzen, dir so transparent wie möglich zu machen, wie
      und wofür wir deine Daten verwenden. Wir gehen dabei natürlich verantwortungsvoll mit deinen Daten um und nutzen sie nur im Rahmen der
      Vorgaben der geltenden Datenschutz-Gesetze, insbesondere der EU-Datenschutzgrundverordnung (EU-DSGVO).
    </p>
    <p>
      Insbesondere bemühen wir uns, die MissionMe-Webseite und alle damit zusammenhängenden Angebote und Services ständig zu verbessern und
      besser auf deine und die Bedürfnisse anderer Nutzer zuzuschneiden. Das kann allerdings nur gelingen, wenn wir beobachten und
      auswerten, wie diese Angebote und Services genutzt werden. Im Folgenden informieren wir dich umfassend darüber, was mit deinen Daten
      geschieht – vor allem darüber, was wie und warum damit passiert. Auch alle Informationen, die wir gemäß der
      EU-Datenschutzgrundverordnung verpflichtend bereitstellen müssen, findest du hier.
      <strong>Verantwortlich</strong> für den Schutz deiner personenbezogenen Daten und die Einhaltung der EU-Datenschutzgrundverordnung ist
      die Gruner + Jahr Deutschland GmbH in 20459, Hamburg (im Folgenden kurz: MissionMe). Sie betreibt die oben genannten Services. Weitere
      Kontaktdaten, Ansprechpartner und Pflichtangaben zur Gruner + Jahr Deutschland GmbH findest du{' '}
      <a href="https://missionme.de/imprint">hier.</a>
    </p>
    <p>
      Wenn du noch Fragen zum Thema Datenschutz an die Gruner + Jahr Deutschland GmbH hast oder deine Rechte in Sachen Datenschutz (siehe
      unten) wahrnehmen möchtest, kannst du dich an unseren Datenschutzbeauftragten wenden. Du erreichst ihn unter der E-Mail-Adresse{' '}
      <a href="mailto:datenschutz@missionme.de">datenschutz@missionme.de</a> oder per Briefpost an Gruner + Jahr Deutschland GmbH, c/o
      Datenschutz MissionMe, Koreastraße 7, 20457 Hamburg.
    </p>
    <p>
      Diese Datenschutz-Hinweise gelten für alle Online-Angebote und -Services, die unter der Marke MissionMe abrufbar sind. Dies ist
      aktuell:
    </p>
    <ul>
      <li>
        die <strong>MissionMe Webseite</strong> unter der Domain <a href="https://www.missionme.de/">https://www.missionme.de/</a>
      </li>
    </ul>
    <p>Die o.g. Angebote und Services werden nachfolgend kurz einfach &quot;Services&quot; genannt.</p>
    <hr />
    <h2 id="inhaltsverzeichnis-">Inhaltsverzeichnis:</h2>
    <h3 id="-das-wichtigste-zusammengefasst-auf-einen-blick">► Das Wichtigste zusammengefasst auf einen Blick</h3>
    <h4 id="i-welche-daten-werden-bei-nutzung-unserer-services-erfasst-">I. Welche Daten werden bei Nutzung unserer Services erfasst?</h4>
    <h5 id="ii-warum-werden-diese-daten-verarbeitet-">II. Warum werden diese Daten verarbeitet?</h5>
    <h5 id="iii-gehen-daten-auch-an-dritte-oder-ins-eu-ausland-">III. Gehen Daten auch an Dritte oder ins EU-Ausland?</h5>
    <h3 id="-deine-rechte-als-betroffener">► Deine Rechte als Betroffener</h3>
    <h3 id="-die-einzelnen-daten-verarbeitungen-im-detail">► Die einzelnen Daten-Verarbeitungen im Detail</h3>
    <h4 id="a-bereitstellung-der-services-und-funktionalit-ten-der-missionme-webseite">
      A. Bereitstellung der Services und Funktionalitäten der MissionMe-Webseite
    </h4>
    <h5 id="-kontaktformular-und-support-anfragen">- Kontaktformular und Support-Anfragen</h5>
    <h5 id="-weitere-cookie-basierte-funktionalit-ten">- (weitere) Cookie-basierte Funktionalitäten</h5>
    <h4 id="b-verbesserung-der-missionme-webseite">B. Verbesserung der MissionMe-Webseite</h4>
    <h5 id="-auswerten-des-nutzungsverhaltens-auf-der-webseite-via-google-analytics-">
      - Auswerten des Nutzungsverhaltens auf der Webseite (via Google Analytics)
    </h5>
    <h5 id="-analyse-der-website-performance-inklusive-fehlern-und-abst-rzen-via-bugsnag-">
      - Analyse der Website Performance inklusive Fehlern und Abstürzen (via Bugsnag)
    </h5>
    <br />
    <br />
    <h3 id="-das-wichtigste-zusammengefasst-auf-einen-blick">► Das Wichtigste zusammengefasst auf einen Blick</h3>
    <h5 id="i-welche-daten-werden-bei-nutzung-unserer-dienste-erfasst-">I. Welche Daten werden bei Nutzung unserer Dienste erfasst?</h5>
    <p>
      <strong>Direkteingabe von Klardaten.</strong> Wenn du auf der MissionMe-Webseite ein Kontaktformular für Support-Anfragen nutzt,
      fragen wir über die entsprechenden Formulare personenbezogenen Daten von dir ab, die erkennbar und direkt zu dir bzw. deiner Identität
      gehören (sog. Klardaten).
    </p>
    <p>
      Zu diesen Klardaten gehören insbesondere Name, Anrede und E-Mail-Adresse. Zusätzlich kannst du freiwillig auch weitere persönliche
      Angaben zu dir machen, die dann auch gespeichert werden, beispielsweise in deinem Benutzer-Profil.
    </p>
    <p>
      Datenschutzrechtlich besonders sensible Daten (sog. besondere Kategorien von Daten nach Artikel 9 DSGVO - etwa zu Gesundheitszustand,
      Ethnie, Weltanschauung, Genetik, Intimität) fragen wir an keiner Stelle ab. Ebenso gibt es keine speziell auf Kinder zugeschnittenen
      Services oder Angebote.
    </p>
    <p>
      <strong>Daten-Anreicherung.</strong> Wir reichern deinen Datensatz mitunter durch eigene Beobachtungen an, jedoch nur im Hinblick auf
      vermutete Interessen und nur soweit in diesen Datenschutz-Hinweisen beschrieben.{' '}
    </p>
    <p>
      <strong>Pseudonymisierte Daten.</strong> Darüber hinaus werden auch Daten verarbeitet, die keinen direkt erkennbaren Bezug zu dir als
      Person haben (sog. pseudonymisierte Daten). Pseudonymisiert meint, dass man dich bzw. deinen Computer oder Browser zwar unter einer ID
      (“Pseudonym”) wiedererkennen könnte, aber mit üblichen Mitteln nicht herauszubekommen ist, wer genau du bist oder wie man dich
      kontaktieren kann. Anders gesagt: Pseudonyme werden nicht mit Klardaten wie Name oder E-Mail-Adresse zusammengespielt, schlicht weil
      wir in diesem Fall nicht mehr zu wissen brauchen als nötig.
    </p>
    <p>
      Das gilt beispielsweise, wenn wir herausfinden möchten, welche Bereiche der MissionMe-Webseite besonders häufig geklickt werden und
      welche gar nicht, oder wenn wir dir nicht immer die gleichen Inhalte auf der Webseite anzeigen wollen.
    </p>
    <p>
      <strong>Weitere Details.</strong> Wenn du einzelne Dinge noch genauer wissen möchtest, haben wir im Kapitel „Die einzelnen
      Daten-Verarbeitungen im Detail“ die näheren Details für dich zusammengestellt.
    </p>
    <h5 id="ii-warum-werden-diese-daten-verarbeitet-">II. Warum werden diese Daten verarbeitet?</h5>
    <p>Die Verarbeitung personenbezogener Daten erfolgt vor allem zu folgenden Zwecken bzw. aufgrund folgender berechtigter Interessen:</p>
    <ul>
      <li>
        zur Personalisierung: um dir Inhalte der MissionMe-Webseite vorzuschlagen, die deinen Bedürfnissen am besten entsprechen, oder, um
        dich per E-Mail auf für dich interessante Inhalte, Hinweise und Angebote hinzuweisen;
      </li>
      <li>
        zur Optimierung: um herauszufinden, was unsere Nutzer besonders begeistert oder stört und wie unsere Services verbessert werden
        können;
      </li>
      <li>
        zur Sicherstellung des Betriebs: um Angriffsmuster zu erkennen und abzuwehren sowie Fehler im System aufzudecken, um zu verhindern,
        dass du gegen deinen Willen E-Mails von uns erhältst;
      </li>
      <li>
        zum Pflegen der Kundenbeziehung und Direktmarketing in eigener Sache: um dich über neue Angebote und Funktionen zu informieren;
      </li>
      <li>zur Betrugsprävention;</li>
      <li>
        zur Erfüllung gesetzlicher Vorgaben, insbesondere handels- und steuerlicher Pflichten, gegebenenfalls auch Auskunftspflichten
        gegenüber Behörden sowie zur Abwehr oder Durchsetzung von Ansprüchen;
      </li>
    </ul>
    <p>
      Die Verarbeitung deiner personenbezogenen Daten erfolgt dabei rechtmäßig auf Grundlage der EU-Datenschutzgrundverordnung, und zwar -
      je nach Fall - auf Basis deiner Einwilligung, eines Vertragsschlusses mit dir, zur Erfüllung gesetzlicher bzw. behördlicher Vorgaben
      und/oder nach Abwägung berechtigter Interessen im Einzelfall (siehe DSGVO Artikel 6 Absatz 1 Buchstaben a), b), c) und f)).
    </p>
    <p>
      Soweit wir Daten auf Basis einer Einwilligung oder aufgrund einer Abwägung berechtigter Interessen verarbeiten, tun wir dies nur,
      solange du nicht widersprichst bzw. die Einwilligung widerrufst. Näheres dazu erläutern wir weiter unten in den Details.
    </p>
    <h5 id="iii-gehen-daten-auch-an-dritte-oder-ins-eu-ausland-">III. Gehen Daten auch an Dritte oder ins EU-Ausland?</h5>
    <p>
      MissionMe verzichtet auf die kommerzielle Weitergabe deiner Daten (Verkauf, Vermietung) an Dritte und betreibt keinen Adresshandel.
    </p>
    <p>
      Dennoch gilt: Es gibt Dinge, die andere besser können als wir. Daher machen wir nicht alles selbst, sondern haben einige{' '}
      <strong>Dienstleister</strong> eingeschaltet, mit denen wir nur zum Teil als Gesellschaft in einem Konzernverbund stehen. Manche
      Dienstleister werden dabei Zugriff auf personenbezogene Daten haben müssen oder zumindest haben können. Das betrifft insbesondere die
      Technik, mit der wir unseren Service oder einzelne Funktionalitäten und Angebote betreiben, überwachen und analysieren.{' '}
    </p>
    <p>
      Alle unsere Dienstleister beauftragen wir strikt nach den Vorgaben der EU-Datenschutz-Grundverordnung schriftlich und lassen uns
      beispielsweise auch technische und organisatorische Maßnahmen erläutern, mit denen die Dienstleister, die ihnen anvertrauten,
      personenbezogenen Daten vor Missbrauch schützen.
    </p>
    <p>
      Einige der von uns beauftragten IT-Dienstleister haben ihren Sitz nicht innerhalb der EU bzw. des Europäischen Wirtschaftsraums (EWR)
      oder speichern und verarbeiten personenbezogene Daten dort. Soweit nicht in diesen Gebieten nach Ansicht der EU-Kommission ohnehin
      dasselbe Datenschutz-Niveau wie in Deutschland besteht, bestehen wir immer auf die datenschutzrechtlich erforderlichen Garantien für
      einen solchen Auslands-Transfer. In der Regel ist dies der Abschluss seitens der EU-Kommission vorgegebener Datenschutz-Verträge (sog.
      EU-Standardvertragsklauseln), speziell bei Transfers in die USA zudem die Teilnahme am sog. EU-U.S.-Privacy-Shield.
    </p>
    <p>
      In einigen Fällen geben wir Daten unter Wahrung der datenschutzrechtlichen Vorgaben auch an <strong>Dritte</strong>, die die Daten
      dann <strong>eigenverantwortlich weiterverarbeiten</strong>. Hierzu gehören zum Beispiel die Services der Anbieter wie Microsoft, etwa
      wenn du uns eine Support-Anfrage schickst und Microsoft diese als unser E-Mail-Dienstleister verarbeitet. Näheres dazu erläutern wir
      im nächsten Kapitel.
    </p>
    <h3 id="-deine-rechte-als-betroffener">► Deine Rechte als Betroffener</h3>
    <p>
      Du hast gemäß der EU Datenschutz-Grundverordnung das Recht, zu deinen personenbezogenen Daten Auskunft zu verlangen (siehe Artikel
      15), ebenso eine Berichtigung (siehe Artikel 16), Löschung (siehe Artikel 17) oder zumindest die Einschränkung der Verarbeitung (siehe
      Artikel 18) deiner personenbezogenen Daten zu fordern.
    </p>
    <p>
      Du hast ferner das Recht auf Daten-Übertragbarkeit (siehe Artikel 20). Außerdem hast du natürlich das Recht, jederzeit eine erteilte
      Einwilligung zur Verarbeitung von personenbezogenen Daten zu widerrufen (Artikel 7) sowie einer Verarbeitung zu widersprechen, die auf
      Basis der Abwägung berechtigter Interessen erfolgt (siehe Artikel 21 Absatz 4 DSGVO). Darüber hinaus hast du ein Beschwerderecht bei
      der zuständigen Datenschutzaufsichtsbehörde.
    </p>
    <p>
      Wenn du Fragen hierzu oder sonst zum Thema Datenschutz an uns hast oder aber deine Rechte in Sachen Datenschutz wahrnehmen möchtest,
      wende dich gern an unseren <strong>Datenschutzbeauftragten</strong>. Du erreichst ihn unter der E-Mail-Adresse{' '}
      <a href="mailto:datenschutz@missionme.de">datenschutz@missionme.de</a> oder per Briefpost an Gruner + Jahr Deutschland GmbH, c/o
      Datenschutz MissionMe, Koreastraße 7, 20457 Hamburg.
    </p>
    <h3 id="-die-einzelnen-daten-verarbeitungen-im-detail">► Die einzelnen Daten-Verarbeitungen im Detail</h3>
    <p>
      Um Dir einen leichteren Überblick zu ermöglichen, haben wir die Datenschutz-Hinweise danach gegliedert, inwiefern sie (A) die
      grundsätzliche Bereitstellung der Services und Funktionalitäten der MissionMe-Webseite, (B) die Optimierung unserer Services oder (C )
      die Optimierung unserer Marketing-Aktivitäten betreffen.
    </p>
    <h4 id="a-datenverarbeitung-zur-bereitstellung-der-missionme-webseite">
      A. Datenverarbeitung zur Bereitstellung der MissionMe-Webseite
    </h4>
    <p>
      Im Folgenden sind Details zu einzelnen Bereichen, Services und Funktionalitäten zur Bereitstellung der MissionMe-Webseite genauer
      erläutert.
    </p>
    <h5 id="support-anfragen-via-e-mail-dienstleister">
      Support-Anfragen (via E-Mail-Dienstleister)
    </h5>
    <p>
      Soweit du MissionMe kontaktierst, verarbeitet unser E-Mail-Dienstleister Microsoft, vertreten durch Microsoft Corporation, One
      Microsoft Way, Redmond, WA 98052, USA, deine Kontaktdaten sowie den Inhalt deiner Anfrage.
    </p>
    <p>
      Anfragen über E-Mail können Kommunikations-, und Vertragsdaten sowie deine Nutzerhistorie betreffen. Die
      angegebenen Daten werden vertraulich behandelt. Die angegebenen Daten und der Nachrichtenverlauf mit unserem Kundenservice wird für
      Anschlussfragen und spätere Kontaktaufnahmen gespeichert.
    </p>
    <p>
      Soweit du uns per E-Mail oder über ein Formular kontaktierst, verwenden wir die von Dir übermittelten personenbezogenen Daten aufgrund
      berechtigter Interessen, ausschließlich um deine Anfrage zu beantworten.
    </p>
    <p>
      Microsoft kann die E-Mail-Daten in den Vereinigten Staaten von Amerika oder in jedem anderen Land verarbeiten und speichern, in dem
      Microsoft oder ihre Unterauftragsverarbeiter tätig sind. Microsoft sichert zu, die Anforderungen des Europäischen Wirtschaftsraums und
      des schweizerischen Datenschutzgesetzes bezüglich Erhebung, Nutzung, Übertragung, Speicherung und sonstiger Verarbeitung
      personenbezogener Daten aus dem Europäischen Wirtschaftsraum und der Schweiz einzuhalten. Alle Übertragungen personenbezogener Daten
      an ein Drittland oder eine internationale Organisation unterliegen angemessenen Absicherungen, wie sie in Art. 46 DSGVO beschrieben
      sind, und solche Übertragungen und Absicherungen werden nach Art. 30 Absatz 2 DSGVO dokumentiert. Darüber hinaus sind alle
      Übertragungen in die USA nach „Privacy Shield“-Zertifizierung geschützt:{' '}
      <a href="https://www.privacyshield.gov/list">https://www.privacyshield.gov/list</a>.
    </p>
    <p>
      Anfragen zur <strong>Löschung deines Benutzerprofils</strong> über unsere Kontaktkanäle speichern wir in unseren hauseigenen Systemen,
      um nachvollziehen und nachweisen zu können, dass deine Anfrage erfolgreich bearbeitet wurde (Nachweispflicht). Deine Daten
      (E-Mail-Adresse und UserID) werden spätestens nach einem Jahr und einem Monat aus unserem System gelöscht. Die Daten sind vor nicht
      berechtigten Zugriffen geschützt im System gespeichert und werden nicht an Dritte weitergegeben.
      <strong>Widerruf / Opt-Out-Möglichkeit:</strong> Eine Löschung deiner Kundenanfragen erfolgt nach 5 Jahren oder bei direktem Widerruf
      an <a href="mailto:datenschutz@missionme.de">datenschutz@missionme.de</a>.
    </p>
    <p>
      Wenn und soweit die mit deinen E-Mail Anfragen verbundenen Daten noch für Zwecke genutzt werden können und müssen, die zum Zeitpunkt
      der gewünschten bzw. geplanten Löschung noch nicht entfallen sind, werden die Datensätze anstelle einer Löschung zumindest gesperrt
      bzw. auf bestimmte Verarbeitungszwecke beschränkt. Das ist insbesondere der Fall bei gesetzlich zwingend vorgeschriebenen
      Aufbewahrungspflichten wie etwa entsprechenden handels- und steuerrechtlichen Vorschriften. Letztere können bis zu 10 Jahren betragen
      (siehe § 147 Abs. 3 Abgabenordnung).
    </p>
    <h5 id="-weitere-cookie-basierte-funktionalit-ten">(weitere) Cookie-basierte Funktionalitäten</h5>
    <p>
      Um das Surfen auf der MissionMe-Website zu verbessern, verwenden wir sogenannte Cookies (kleine Dateien mit
      Konfigurationsinformationen). Cookies werden auf der MissionMe-Webseite verwendet, um die Nutzerfreundlichkeit zu steigern und die
      MissionMe-Webseite bei jedem Aufruf möglichst individuell und bedarfsgerecht zu gestalten. Des Weiteren wird auf der
      MissionMe-Webseite ein Cookie-Banner-Cookie gesetzt. Mit Hilfe dieses Cookies merken wir uns, ob du bereits Besucher auf unserer Seite
      warst und die Cookies akzeptiert hast (gemäß der &quot;Cookie-Richtlinie“ der EU, offizieller Name: E-Privacy-Richtlinie 2009/136/EG).
      Um dir eine erneute Anzeige des störenden Hinweises zu ersparen, wird der Cookie nach drei Monaten automatisch gelöscht, sodass du
      erst nach Ablauf der Gültigkeit den Cookie-Banner erneut bestätigen musst. Derartige Cookies werden nicht nur von der
      MissionMe-Webseite selbst gesetzt, sondern in deren Auftrag auch von Drittanbietern (siehe dazu unten). Beim Aufruf einer Seite auf{' '}
      <a href="www.missionme.de">www.missionme.de</a> werden auch Cookies gesetzt, die über deinen aktuellen Besuch auf{' '}
      <a href="www.missionme.de">www.missionme.de</a> (sog. Session) hinaus gespeichert bleiben.
    </p>
    <p>
      <strong>Allgemeine Browserdaten:</strong>
      Die MissionMe-Website erhebt und speichert ebenfalls in Cookies automatisiert Informationen, die dein Webbrowser übermittelt, den du
      zum Aufrufen der Webseiten von <a href="www.missionme.de">www.missionme.de</a> verwendest. Dies sind insbesondere Details zum
      verwendeten Browser und Betriebssystem, ein Hinweis auf die Herkunft der zuvor besuchten Seiten (sog. Referral URL), die IP-Adresse
      bzw. der Host-Name des zugreifenden Rechners sowie die Uhrzeit der Seitenanfrage. Diese Daten dienen der statistischen Auswertung der
      Seiten von <a href="www.missionme.de">www.missionme.de</a>. Die MissionMe-Website wird die vorhandenen Nutzungsdaten nicht mit deinen
      Namens- oder Adressdaten verbinden, die z. B. bei einer Kontaktaufnahme über das Kontaktformular abgefragt werden (sog.
      Bestandsdaten); die erhobenen, pseudonymen Nutzungsdaten werden zu langfristigen Auswertungszwecken verwendet und erst mit dem Ende
      der Auswertungsphase oder nach gesetzlicher Vorgabe gelöscht.
    </p>
    <p>
      <strong>Widerruf / Opt-Out-Möglichkeit:</strong> Solltest du den Einsatz von Cookies nicht wünschen oder bestehende Cookies löschen
      wollen, kannst du diese über deinen Internetbrowser abschalten und entfernen. Über die folgenden Links findest du Hilfe zur Löschung
      von Cookies für die gängigsten Browser: •{' '}
      <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a>•{' '}
      <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">Mozilla Firefox</a>•{' '}
      <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">Safari</a>•{' '}
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=de">Chrome</a>
    </p>
    <p>
      Die MissionMe-Webseite verwendet auch für Analysezwecke analytische Cookies von Drittanbietern, beispielsweise von Google und
      Facebook. Der Verwendung von Analyseprogrammen durch die MissionMe-Webseite und der Datenerhebung (pseudonymisierte Daten) durch
      Partnerunternehmen kann jederzeit mit Wirkung für die Zukunft widersprochen werden. Diese Funktionen werden von den jeweiligen
      Betreibern angeboten und bereitgestellt und findest du im dazugehörigen Hinweis nochmals beschrieben.
    </p>
    <h4 id="b-einzelne-analysen-und-auswertungen-zur-verbesserung-der-missionme-webseite">
      B. Einzelne Analysen und Auswertungen zur Verbesserung der MissionMe-Webseite
    </h4>
    <h5 id="auswerten-des-nutzungsverhaltens-auf-der-missionme-webseite">Auswerten des Nutzungsverhaltens auf der MissionMe-Webseite</h5>
    <p>
      Für die Auswertung von Nutzerverhalten auf seiner Webseite nutzt MissionMe den Dienst Google Analytics, der von Google betrieben wird.
      Da MissionMe in Deutschland ansässig ist, ist der Partner die europäische Google Tochter „Google Ireland Limited“, Gordon House,
      Barrow Street, Dublin, D04 E5W5, Dublin, Irland. Zur Auswertung des Nutzerverhaltens wird ein Cookie gesetzt. Die durch dieses Cookie
      erzeugten Informationen über deine Benutzung dieser Website (einschließlich deiner IP-Adresse) werden an einen Server von Google in
      den USA übertragen und dort gespeichert.
    </p>
    <p>
      Die MissionMe-Webseite setzt Google Analytics ausschließlich mit der Erweiterung der IP-Anonymisierung ein, so dass IP-Adressen nur
      verkürzt weiterverarbeitet werden, um eine direkte Personenbeziehbarkeit auszuschließen. Durch die IP-Anonymisierung wird die
      IP-Adresse von Google innerhalb von Mitgliedsstaaten der EU oder in anderen Vertragsstaaten des Abkommens über den europäischen
      Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Google wird diese Informationen benutzen, um deine Nutzung der Webseite auszuwerten, um Reports über die Website Aktivitäten
      zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.
    </p>
    <p>
      Google wird - zumindest nach eigenen Angaben - in keinem Fall deine IP-Adresse mit anderen Daten von Google in Verbindung bringen.
      Google kann jedoch die relevanten persönlichen Daten in sämtlichen Einrichtungen speichern und verarbeiten, die von Google, seinen
      internen Subprozessoren oder den genutzten Anbietern von digitaler Infrastruktur unterhalten werden. In allen Fällen, wo diese Daten
      die EEA (European Economic Area) beziehungsweise Schweiz verlassen, verpflichtet sich Google, die Selbstzertifizierung unter dem EU-US
      beziehungsweise Swiss-US Privacy Shield (<a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>) aufrecht zu
      erhalten und sicherzustellen, dass das jeweilige Privacy Shield auch persönliche Daten von Kunden mit einbezieht.
    </p>
    <p>
      Google behält sich vor, zu Google gehörige Firmen sowie Dritt-Firmen zur Bereitstellung seiner Dienste zu beauftragen. Wenn Google die
      Services einer dieser Firmen in Anspruch nimmt, so hält es stets in einem schriftlichen Vertrag folgende Regeln fest. Erstens hat der
      jeweilige Dritte nur auf solche Daten Zugriff, die zur Erfüllung seines Dienstes notwendig sind. Zweitens erfolgt dies im Rahmen der
      Zertifizierung nach EU/US Privacy Shield (<a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>) beziehungsweise
      den EU-DSGVO Regelungen. Die Datenverarbeitung durch die Services von Google Analytics ist zudem gemäß den Sicherheitsstandards ISO
      27001 geprüft und zertifiziert. Durch die Nutzung MissionMe-Webseite erklärst du dich mit der Bearbeitung der über dich erhobenen
      Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Mehr zu den Sicherheits-
      und Datenschutzgrundsätzen von Google Analytics erfährst du <a href="https://policies.google.com/privacy?hl=de">hier</a>.
    </p>
    <p>
      <strong>Widerruf / Opt-Out-Möglichkeit:</strong> Der Datenerhebung und -speicherung durch Google Analytics kann jederzeit mit Wirkung
      für die Zukunft widersprochen werden. Du hast dazu die Möglichkeit, ein von Google herausgegebenes Browser-Plugin zu installieren.
      Dieses steht für verschiedene Browser-Versionen zur Verfügung und kann unter{' '}
      <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a> heruntergeladen werden.
    </p>
    <p>
      Wenn und soweit die mit deinem Benutzerkonto verbundenen Daten noch für Zwecke genutzt werden können und müssen, die zum Zeitpunkt der
      gewünschten bzw. geplanten Löschung noch nicht entfallen sind, werden die Datensätze anstelle einer Löschung zumindest gesperrt bzw.
      auf bestimmte Verarbeitungszwecke beschränkt. Das ist insbesondere der Fall bei gesetzlich zwingend vorgeschriebenen
      Aufbewahrungspflichten wie etwa entsprechenden handels- und steuerrechtlichen Vorschriften. Letztere können bis zu 10 Jahren betragen
      (siehe § 147 Abs. 3 Abgabenordnung).
    </p>
    <h5 id="analyse-der-website-performance-inklusive-fehlern-und-abst-rzen-via-bugsnag-">
      Analyse der Website Performance inklusive Fehlern und Abstürzen (via Bugsnag)
    </h5>
    <p>
      Für die Auswertung der Website-Performance nutzt die Schläfer-Webseite mehrere Dienstleister, die Auswertungen zu Performance-Angaben
      der Webseite (wie z.B. Ladezeiten) ermöglichen.
    </p>
    <p>Hierfür arbeitet MissionMe mit folgenden Dienstleistern zusammen:</p>
    <ol>
      <li>
        <strong>Bugsnag</strong>, ein Dienst der von LogRocket Inc. betrieben wird. LogRocket Inc. sitzt in B6201, One Kendall Square,
        Cambridge, MA 02139 in den Vereinigten Staaten.
      </li>
    </ol>
    <p>
      Bugsnag ist ein Dienst, der Fehler und Abstürze in der MissionMe-Webseite erkennt, diese auswertet und zu Fehler-Berichten
      zusammenfasst. Zu diesem Zweck werden folgende Daten erfasst: Fehlermeldungen mit aktuellem Fehlerkontext, User-ID und die IP-Adresse.
      Daraus werden Fehlerberichte erstellt, welche die MissionMe-Webseite nutzt, um Fehler in der Webseite zu identifizieren und beheben zu
      können. MissionMe nutzt die Dienste von Bugsnag im Rahmen der EU-Datenschutz-Grundverordnung aufgrund des Interesses, die Webseite für
      Nutzer möglichst fehlerfrei zu gestalten, die Funktionalitäten der Webseite stetig zu verbessern und so das Nutzererlebnis zu
      optimieren. LogRocket als Betreiber des Diensts Bugsnag erfasst, verarbeitet und speichert die oben beschriebenen Daten auf seinen
      Servern. Hierbei behält LogRocket sich vor, personenbezogene Daten aus der EEA (European Economic Area) in die Vereinigten Staaten zu
      übermitteln, um seinen Dienst durchzuführen. Dies geschieht stets unter Berücksichtigung der Transfer-Garantie EU-US Privacy Shield:{' '}
      <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.
    </p>
    <p>
      LogRocket behält sich zudem vor, Dritte für die Erfüllung seiner Services beauftragen. Diese können dann auch begrenzten Zugriff auf
      die übermittelten Daten haben, allerdings nur für die direkte Bereitstellung des jeweiligen Dienstes. Derzeit gibt LogRocket folgende
      beauftragte Dritt-Unternehmen an: Google Cloud Platform, Amazon Web Services, Redis Labs. LogRocket garantiert, dass die Verarbeitung
      der Daten durch Dritt-Unternehmen nur zum Zweck der beauftragten Service-Erfüllung erfolgt und nicht für andere Zwecke genutzt werden.
      Die beauftragten Dritt-Unternehmen unterliegen vertraglichen Rahmenbedingungen, die die Vertraulichkeit und Grundrechte im Sinne der
      EU-Datenschutzgrundverordnung gewährleisten.
    </p>
    <p>
      <strong>Widerruf / Opt-Out-Möglichkeit:</strong> Wenn du der Verarbeitung dieser Daten widersprechen möchtest, kannst du deinen
      Widerruf jederzeit per E-mail an <a href="mailto:datenschutz@missionme.de">datenschutz@missionme.de</a> richten. Wir leiten diese
      Anfrage dann an LogRocket weiter. LogRocket verpflichtet sich, den von uns weitergeleiteten Anweisungen Folge zu leisten. Die Löschung
      der Daten erfolgt im Rahmen der rechtlichen Vorgaben, d.h. gesetzliche Aufbewahrungs- und Nachweispflichten werden dabei
      berücksichtigt. Daneben werden nach dem Ende der Nutzung des Dienstes von Bugsnag sämtliche Daten gelöscht, sobald wir dies als
      MissionMe anordnen.
    </p>
    <p>
      <em>Version: 1.0 - Wir behalten uns vor, diese Datenschutzerklärung anzupassen.</em>
    </p>
  </Layout>
);

export default DataPrivacyPage;
